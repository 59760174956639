import PropTypes from 'prop-types';

const PageLoader = (props) => {
  const { loadingMsg, isHScreen = true } = props;

  const loadingImg = "https://cdn.auth0.com/blog/hello-auth0/loader.svg";

  return (
    <div className={`flex flex-col justify-center items-center ${isHScreen ? 'h-screen' : 'h-full'} w-full`}>
      <img src={loadingImg} alt="Loading..." className="w-20 animate-[spin_2s_linear_infinite]" />
      <p className="pt-6 text-lg font-semibold text-gray-400">
        {loadingMsg || "Loading, please wait..."}
      </p>
    </div>
  );
};

PageLoader.propTypes = {
  loadingMsg: PropTypes.string,
  isHScreen: PropTypes.bool
}


export default PageLoader;