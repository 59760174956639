import React, { useState } from "react";
import axios from "axios";
import DOMPurify from "dompurify";

export default function FlashValuation(props) {
  const { demo } = props;
  const [success, setSuccess] = useState(false);
  const [revenue, setRevenue] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [templateHtml, setTemplateHtml] = useState();

  const loadingImg = "https://cdn.auth0.com/blog/hello-auth0/loader.svg";

  async function handleSubmit(event) {
    event.preventDefault();
    if (revenue !== "" && email !== "") {
      const payload = {
        revenue: revenue,
        email: email,
      };

      setLoading(true);
      await axios
        .post(`${process.env.REACT_APP_API_DOMAIN}/flashvaluation`, payload)
        .then((res) => {
          if (res.status === 200) {
            setLoading(false);
            setSuccess(true);
            setTemplateHtml(DOMPurify.sanitize(res.data.html));
          }
        });
    }
  }
  return (
    <div
      style={{
        width: "100%",
        padding: "25px",
        backgroundColor: "white",
        position: "relative",
        overflowY: "auto",
      }}
    >
      <div
        style={{
          position: "absolute",
          left: "0",
          top: "0",
          width: "100%",
          height: "100%",
          background: "rgb(171, 171, 171, .5)",
          display: `${loading ? "block" : "none"}`,
        }}
      >
      </div>
      {loading && (
        <div
          style={{
            position: "absolute",
            top: "45%",
            left: "44%",
            zIndex: "11",
          }}
        >
          <div className="loader">
            <img src={loadingImg} alt="Loading..." />
          </div>
        </div>
      )}
      {!success ? (
        <>
          <div style={{ fontSize: "30px", fontWeight: "700" }}>
            Get a FREE business evaluation
          </div>
          <div>
            Business Value Based on a Multiple of Revenue is popular rule of
            thumb way to indicate value. However it can be a poor indicator
            because rule thumb approaches lack refinement.
          </div>
          <div style={{ marginBottom: "15px", fontStyle: "italic" }}>
            ~ Don Schreiber, Jr.
          </div>
          <form id="FlashValuationForm" onSubmit={handleSubmit}>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <div style={{ width: "100%" }}>
                <InputField
                  title={"Prior Year Total Revenue"}
                  description={
                    "Prior year total income is the total amount of revenue earned by the business during the previous fiscal year. This is an important metric used to evaluate the financial performance of the business, and is often used as a basis for determining eligibility for loans, credit, and other financial products. Our app uses this metric to help estimate the value of the business."
                  }
                  type={"currency"}
                  setFunc={setRevenue}
                />
                <InputField title={"Email"} type={"email"} setFunc={setEmail} />
              </div>
              <img
                src={"https://valuation-pro.azurewebsites.net/vprologo.png"}
                alt="Valuation Pro logo - the letter v with pro next to it"
                style={{
                  margin: "25px 0",
                  width: "45%",
                  objectFit: "contain",
                  maxWidth: "250px",
                }}
              />
            </div>
            <button
              style={{
                height: "40px",
                padding: "10px",
                fontSize: "13px",
                fontWeight: "600",
                color: "white",
                backgroundColor: "#270949",
                borderRadius: "8px",
              }}
            >
              Get my FREE evaluation
            </button>
          </form>
        </>
      ) : (
        <>
          <SuccessMessage />
          {demo ? <><div style={{ width: "100%", height: "5px", backgroundColor: "rgb(237, 237, 237)", marginTop: "30px" }}></div><div dangerouslySetInnerHTML={{ __html: templateHtml }} /></> : ""}
        </>
      )}
    </div>
  );
}

const InputField = (props) => {
  const [show, setShow] = useState(false);
  const [error, setError] = useState(false);
  const [valueVisible, setValueVisible] = useState("");
  const { title, description, type, setFunc } = props;
  const onChangeInput = (event) => {
    setValueVisible(event.target.value);
    if (type === "email") {
      if (/^\S+@\S+\.\S+$/.test(event.target.value)) {
        setError(false);
        setFunc(event.target.value);
        return;
      } else {
        setError(true);
        setFunc("");
        return;
      }
    } else if (type === "currency") {
      let value =
        event.target.value &&
        parseInt(event.target.value.replace(/,/g, ""), 10);
      if (/^\d+$/.test(value)) {
        setValueVisible(new Intl.NumberFormat().format(value));
        setError(false);
        setFunc(value);
        return;
      } else {
        setError(true);
        setFunc("");
        return;
      }
    }
  };
  const inputOuterContainer = {
    width: "90%",
    minWidth: "160px",
    whiteSpace: "nowrap",
    backgroundColor: "white",
    display: "grid",
    columnGap: "1rem",
    rowGap: "1.5rem",
    gridTemplateColumns: "repeat(6, minmax(0, 1fr))",
  };
  const inputInnerContainer = {
    marginBottom: "1rem",
    gridColumn: "1 / -1",
  };
  const inputTitleContainer = {
    display: "flex",
    justifyContent: "space-between",
  };
  const inputFieldContainer = {
    "--tw-shadow": "0 1px 2px 0 rgb(0 0 0 / 0.05)",
    "--tw-shadow-colored": "0 1px 2px 0 var(--tw-shadow-color)",
    boxShadow:
      "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
    borderRadius: "0.375rem",
    marginTop: "0.25rem",
    position: "relative",
  };
  const inputFieldInput = {
    opacity: "1",
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
    paddingRight: "3rem",
    paddingLeft: "1.75rem",
    "--tw-bg-opacity": "1",
    backgroundColor: "rgb(243 244 246 / var(--tw-bg-opacity))",
    "--tw-border-opacity": "1",
    borderColor: "rgb(209 213 219 / var(--tw-border-opacity))",
    borderRadius: "0.375rem",
    width: "100%",
    display: "block",
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem",
    borderWidth: "1px",
    "--tw-shadow": "0 0 #0000",
  };
  const inputTitleLabel = {
    overflow: " hidden",
    whiteSpace: " nowrap",
    textOverflow: " ellipsis",
    "--tw-text-opacity": "1",
    color: "rgb(55 65 81 / var(--tw-text-opacity))",
    fontWeight: "600",
    fontSize: ".9rem",
    lineHeight: "1.5rem",
    display: "block",
  };
  const tooltip = {
    position: "absolute",
    left: "25px",
    top: "-40px",
    fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
    fontStyle: "normal",
    fontWeight: "400",
    letterSpacing: "normal",
    lineHeight: "1.42857143",
    textAlign: "start",
    textShadow: "none",
    textTransform: "none",
    whiteSpace: "normal",
    wordBreak: "normal",
    wordSpacing: "normal",
    wordWrap: "normal",
    fontSize: "12px",
    zIndex: "100",
  };
  return (
    <>
      <div style={inputOuterContainer}>
        <div style={inputInnerContainer}>
          <div style={inputTitleContainer}>
            <label style={inputTitleLabel}>{title}</label>
            {description && (
              <div
                onMouseEnter={() => setShow(true)}
                onMouseLeave={() => setShow(false)}
                style={{ position: "relative" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="w-4 h-4"
                >
                  <path
                    fillRule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z"
                    clipRule="evenodd"
                  />
                </svg>
                <div
                  style={{
                    ...tooltip,
                    ...{ display: `${show ? "block" : "none"}` },
                  }}
                >
                  <AltTooltip title={title} description={description} />
                </div>
              </div>
            )}
          </div>
          <div style={inputFieldContainer}>
            <div
              style={{
                paddingLeft: "0.75rem",
                alignItems: "center",
                display: "flex",
                left: `${type !== "email" ? "0" : "-4"}px`,
                top: "0px",
                bottom: "0px",
                position: "absolute",
                pointerEvents: "none",
              }}
            >
              <span
                style={{
                  fontSize: "0.875rem",
                  lineHeight: "1.25rem",
                  "--tw-text-opacity": "1",
                  color: "rgb(107 114 128 / var(--tw-text-opacity))",
                }}
              >
                {type !== "email" ? (
                  "$"
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    style={{ width: "17px" }}
                  >
                    <path d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                  </svg>
                )}
              </span>
            </div>
            <input
              onChange={onChangeInput}
              value={valueVisible}
              style={inputFieldInput}
              placeholder={type === "email" ? "example@example.com" : "---"}
            ></input>
          </div>
          <div
            style={{
              position: "absolute",
              lineHeight: "12px",
              fontSize: "12px",
              fontWeight: "600",
              color: "#eb4034",
              display: `${error ? "block" : "none"}`,
            }}
          >
            {`${type === "currency"
              ? "Please enter a valid number"
              : "Please enter a valid email"
              }`}
          </div>
        </div>
      </div>
    </>
  );
};

const AltTooltip = (props) => {
  const tooltipContainer = {
    width: "14rem",
    border: "solid 1px #E5E7EB",
    borderRadius: "4.8px",
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.08)",
    backgroundColor: "white",
  };

  const tooltipTitle = {
    backgroundColor: "#F9FAFB",
    borderBottom: "solid 1px #E5E7EB",
    borderTopRightRadius: "4.8px",
    borderTopLeftRadius: "4.8px",
    padding: "6px 12px",
    fontWeight: "600",
    color: "#111928",
    width: "100%",
  };

  const tooltipTextBox = {
    padding: "6px 12px",
    fontWeight: "300",
    fontSize: "12px",
  };

  return (
    <div style={tooltipContainer}>
      <div style={tooltipTitle}>{props.title}</div>
      <div style={tooltipTextBox}>{props.description}</div>
    </div>
  );
};

const SuccessMessage = () => {
  return (
    <div>
      <div
        style={{
          fontSize: "30px",
          fontWeight: "700",
          textAlign: "center",
          lineHeight: "30px",
        }}
      >
        Your free valuation has been sent!
      </div>
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <img
          src={"https://valuation-pro.azurewebsites.net/vprologo.png"}
          alt="Valuation Pro logo - the letter v next to the word pro bolded"
          width={325}
          style={{ margin: "25px 0" }}
        />
      </div>
      <div style={{ textAlign: "center" }}>
        Take the next step NOW to build a better business with more revenue,
        higher profit, increased personal income, and a business worth more than
        you ever dreamed possible!
      </div>
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <button
          style={{
            height: "40px",
            padding: "10px",
            fontSize: "13px",
            fontWeight: "600",
            backgroundColor: "#face28",
            borderRadius: "8px",
            marginTop: "10px",
          }}
        >
          Get VPro NOW
        </button>
      </div>
    </div>
  );
};

