import { withAuthenticationRequired } from "@auth0/auth0-react";
import PageLoader from "../components/PageLoader";

const AuthenticationGuard = ({ component, ...props }) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <div>
        <PageLoader />
      </div>
    ),
  });

  return <Component {...props} />;
};

export default AuthenticationGuard;